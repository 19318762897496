<template>
  <div class='commenter asset-sidebar__group'>
    <header>
      <h2><widget-toggler name="Comments" />Comments ({{comments.length}})</h2>
      <a href='#' v-if='can_create' @click.prevent="isShown = !isShown" class=''><i class='fas fa-plus'></i> Add</a>
    </header>
    <div class="asset-sidebar__group-content asset-sidebar__group-content--Comments">
      <ul class='segment--tab__list' v-if="comments.length > 0">
        <li class='commenter__comment' v-for='(comment, index) in comments'>
          <blockquote>
            <span>
              <b>{{comment.user_name}}</b> - {{ comment.formatted_date || "now"}}
              <template v-if='comment.editing_user_name != null'>(edited by {{comment.editing_user_name}})</template></span>
            <nl2br tag="p" v-if='!(showUpdate == index)' :text="comment.body" />
          </blockquote>

          <div v-if='showUpdate == index'>
            <form @submit.prevent.stop="updateComment(comment.id, comment.body, index)" class='commenter__inline-form'>
              <textarea required="true" maxlength=160 v-model='comment.body'></textarea>
              <input class='button button--primary button--small' value='Update' type='submit'>
            </form>
          </div>
          <nav>
            <div class='button-group' v-if='(comment.can_update && can_update) && !(showUpdate == index)'>
              <a @click.prevent="editComment(index)" class='button button--small button--secondary'>Edit</a>
              <a @click.prevent="deleteComment(comment.id, index)" class='button button--small button--danger'>Delete</a>
            </div>
          </nav>
        </li>
      </ul>
      <div class='asset-sidebar__empty' v-if="comments.length == 0">
        <p>Comment and add additional quick notes</p>
      </div>
    </div>
    <Teleport to="body">
      <div class='manage_modal__wrapper' v-show='isShown'>
        <div class='manage_modal manage_modal--small'>
          <header>
            <h1><i class='far fa-comment icon'></i>Add Comment</h1>
            <a href='#' class='button button--secondary' @click.prevent.stop='isShown = false'>Cancel</a>
          </header>
          <slot >
            <form class='commenter__form' @submit.prevent.stop="submitForm" v-if='isShown'>
              <div class='form-group'>
                <textarea ref='mainInput' maxlength=160 @keyup="countDown" v-model='body' placeholder="Write something..."></textarea>
                <span class='hint'>{{remainingCount}}/{{maxCount}} characters used</span>
              </div>
              <input type='submit' :disabled='body.length == 0 || body.length > 160' class='button button--success' value='Post Comment'>
            </form>
          </slot>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>
const axios = require('axios');
import Nl2br from 'vue-nl2br'
import WidgetToggler from "./WidgetToggler.vue"
import Teleport from "vue2-teleport"

export default {
  props: ["url", "can_create", "can_update", "user_id", "commentable_type", "commentable_id"],
  components: {
    Nl2br,
    WidgetToggler,
    Teleport
  },
  data: function () {
    return {
      showUpdate: -1,
      maxCount: 160,
      isShown: false,
      remainingCount: 0,
      body: "",
      comments: []
    }
  },
  mounted () {
    this.fetchComments()
  },
  methods: {
    updateComment(id, body, index) {
      var that = this;
      that.$api.put(`/comments/${id}`, {
        comment: {
          body: body
        }
      }).then(function (response) {
        that.fetchComments()
        that.showUpdate = -1;
        that.comment[index].body = "";
      }).catch(error => {
        console.log(error)
      })
    },
    countDown () {
      this.remainingCount = this.body.length;
    },
    fetchComments () {
      var that = this;
      fetch(that.url)
      .then((resp) => resp.json())
      .then(function(data) {
        that.comments = data
      })
    },
    editComment (index) {
      this.showUpdate = index;
    },
    deleteComment (id, index) {
      if(confirm("Are you sure? This is permanent!")){
        var that = this;
        that.$api.delete(`/comments/${id}`).then(function (response) {
          that.comments.splice(index, 1);
          this.fetchComments()
        }).catch(error => {
          console.log(error)
        })
      }
    },
    submitForm () {
      var that = this;
      that.$api.post(`/comments/`, {
        comment: {
          body: that.body,
          commentable_type: that.commentable_type,
          commentable_id: that.commentable_id
        }
      }).then(function (response) {
        that.fetchComments()
        that.isShown = false;
        that.body = ""
      }).catch(error => {
        console.log(error)
      })
    }
  },
}
</script>


<template>
  <div>

    <input type='hidden' :name="name" :value="convertedSelected">

    <multiselect :options="options"
                  label="name" track-by="id" :multiple='true' :closeOnSelect='false' v-model='selected'  placeholder="Type to search"></multiselect>

  </div>
</template>

<script>
import Icon from "../components/icon.vue";
import vClickOutside from 'v-click-outside'
import Multiselect from 'vue-multiselect'
export default {
  props: ["name", "link_name", "linkable_id", "company", "existing", "options"],
  mounted () {
    var that = this;
  },
  components: {
    Icon,
    Multiselect
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    onClickOutside(evt) {
      this.showSearch = false;
      this.arrowCounter = -1;
    },
    remove (index) {
      this.selected.splice(index, 1)
    },
    add (id, url, name) {
      this.showSearch = false;
      this.selected.push({id: id, url: url, name: name})
    },
    onInput () {

      console.log(this.asset_type);

      var that = this;

      if (that.q.length > 0) {
        that.showSearch = true;
      } else {
        that.showSearch = false;
      }

      that.$api.get('/searches', {
        params: {
          q: that.q,
          type: "Asset",
          company: that.only_company ? that.company : "",
          asset_type: that.linkable_id
        }
      })
      .then(function (response) {
        console.log(response.data)
        that.results = response.data;
      })
    }
  },
  computed: {
    convertedSelected: function () {
      return JSON.stringify(this.selected)
    }
  },
  data: function () {
    return {
      q: "",
      results: [],
      only_company: true,
      showSearch: false,
      selected: this.existing
    }
  }
}
</script>

